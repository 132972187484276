import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    HStack,
    Spinner,
    StackDivider,
    Text,
    useToast,
    VStack
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import FFLogo from "../../../assets/Foundry Fuel_multi.jpg";
import {getIDFromCookie} from "../../auth/TokenTools";
import {getCoreUserIDFromCog, getTechnologistIDFromCog, isLoggedIn} from "../../../common/UserAPI/UserCalls";
import {
    getEventCandidates,
    getEventSummary,
    getVoteStatus,
    submitvotes,
    coreUserCheckIn,
    getEventVotingFullCheck
} from "../../../common/EventAPI/EventCalls";
import { useSearchParams } from 'react-router-dom'
import {FaRegUserCircle} from "react-icons/fa";
import Cookies from 'js-cookie';
import { useNavigate } from "react-router-dom";
import placeholder from "../../../assets/placeholder.png";


export default function Vote() {

    const toast = useToast();
    const navigate = useNavigate();

    // Function to get limit of votes
    function getLimit(countVonCount){

        if (countVonCount > 2) {
            return Math.floor(countVonCount * 0.75);
        } else {
            return countVonCount;
        }

    }

    // Cookie Array
    const cookieArray = ["Access", "Refresh", "ID", "eventID", "FFUserType"];

    // Logout Function
    function logout() {
        const allCookies = Cookies.get();
        // Loop through the cookie names and remove each one
        for (let cookieName in allCookies) {
            if( cookieArray.includes(cookieName)) {
                Cookies.remove(cookieName);
            }
        }
        navigate('/');
    }

    // State Variables
    const [techID, setTechID] = useState(0);
    const [justVoted, setJustVoted] = useState(false)
    const [eventID, setEventID] = useState('');
    const [eventData, setEventData] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [coreDataObj, setCoreDataObj] = useState({});
    const [eventDataObj, setEventDataObj] = useState({});
    const [checkedItems, setCheckedItems] = useState({});
    const [coreImage, setCoreImage] = useState("");

    // Local Variables
    const linkAddress=process.env.REACT_APP_COGNITO_REG_GEN;
    var candidateCount = 0;
    var candidateLimit = 0;

    //-------------------------------
    //
    // Do not change this block
    //
    //--------------------------------



    // Handler to toggle checkbox state
    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [name]: checked,
        }));
        console.log(checkedItems);
    };

    const eventVoteStatus = {};

    // Form submission handler
    const handleSubmit = (event) => {
        event.preventDefault();
        const selectedItems = Object.keys(checkedItems).filter((key) => checkedItems[key]);
        console.log('Selected Items:', selectedItems);
        // Do something with the selected items (e.g., send them to an API)
        console.log("CL : " + candidateLimit);
        console.log("SI : " + selectedItems.length);
        if(selectedItems.length <= candidateLimit){
            submitvotes(coreDataObj.eventcode, eventDataObj.techID, selectedItems).then((response3) => {
                if(response3.data){
                    Cookies.remove('eventID');
                    navigate('/dashboard/tech/thanks');
                }
            });
            }
        else{
            toast({
                title: 'Too many items selected!',
                description: "You can only select " + candidateLimit + " of the options available. Please de-select something.",
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    //-------------------------------
    //-------------------------------

    // THIS IS WHERE THINGS NEED FIXED

    // 1 Check logged in and redirect if not



    if(!isLoggedIn()){
        Cookies.set('eventID', searchParams.get('event'), { expires: 1 });
        window.location.href = 'mobilelogin';
    }
    else{

        // Need to load core data object
        if(!Object.keys(coreDataObj).length > 0){

            getIDFromCookie().then((response) => {
                // Create the outbound data object
                let dataobjectOut = { cogcode:response,eventcode:searchParams.get('event'),eventImage:""};
                let eventSum = getEventSummary(searchParams.get('event')).then((response3) => {
                    var holder = dataobjectOut;
                    holder["eventImage"]=response3.data.hostImage;
                    console.log("Setting Core Data");
                    setCoreDataObj(holder);
                })
            });

        }
        else if(Object.keys(coreDataObj).length > 0 && !Object.keys(eventDataObj).length > 0){

            getEventVotingFullCheck(coreDataObj).then((response2) => {

                if(Object.keys(response2.data).length > 0){
                    console.log("Setting Event Data");
                    setEventDataObj(response2.data);
                    //console.log(JSON.stringify(response2.data));
                }


            });
        }
        else if(Object.keys(coreDataObj).length > 0 && Object.keys(eventDataObj).length > 0){

            candidateCount=eventDataObj.candidates.length;
            candidateLimit=getLimit(eventDataObj.candidates.length);
            console.log("Both Loaded for tech " + eventDataObj.techID);
            //console.log(JSON.stringify(coreDataObj));
            //console.log(JSON.stringify(eventDataObj));
            // console.log(eventDataObj.checkResult);
            // console.log(eventDataObj.techID);
            // console.log(eventDataObj.message);
            // console.log("Event Locked : " + eventDataObj.eventStatus.eventLocked);

        }



    }


    if((!Object.keys(coreDataObj).length > 0 || !Object.keys(eventDataObj).length > 0)){
        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Container><Spinner thickness='4px' speed='0.65s' emptyColor='gray.200' color='blue.500' size='xl' /></Container>

                        <Box>Loading Voting!</Box>
                    </VStack>
                </Box>
            </Container>
        );
    }
    else if(!eventDataObj.checkResult){

        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Box>{eventDataObj.message}</Box>
                        <Box><Button colorScheme="red" onClick={event =>  logout()}>Logout</Button></Box>
                    </VStack>
                </Box>
            </Container>
        );

    }
    else if(!justVoted && eventDataObj.checkResult){

        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>

                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <form onSubmit={handleSubmit}>

                            <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                                <Box alignContent={'center'} textAlign={'center'}>
                                    <center><img src={'data:image/jpeg;base64,' + coreDataObj.eventImage} width={'300px'}/>
                                    </center>
                                    <p>powered by</p>
                                    <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'150px'}/></center>

                                </Box>


                                <Box> The list below shows the opportunities you can vote on at this session. You can vote on a
                                    maximum of {candidateLimit} items from the {candidateCount} being presented.</Box>
                                <Box>
                                    <VStack divider={<StackDivider borderColor='gray.200'/>} align='stretch'>

                                        // List the Opportunity candidates for this event
                                        {eventDataObj.candidates && eventDataObj.candidates.map(item => (
                                            <Box key={item.id} borderRadius='lg' minHeight={'80px'}>
                                                <HStack spacing='0px'>
                                                    <Box w='90px' marginRight={'10px'}>
                                                        {item.businessLogo ? <img src={'data:image/jpeg;base64,' + item.businessLogo} width={'70px'}/> : <img src={placeholder} width={'70px'}/>}</Box>
                                                    <Box w='210px' textAlign={"left"}>
                                                        <Text fontSize='lg'>{item.name}</Text>
                                                        <Text fontSize='md'>{item.businessName}</Text>
                                                        <Text fontSize='xs'>{item.oType}</Text>
                                                    </Box>
                                                    <Box w='50px' paddingTop={'30px'}>
                                                        <Checkbox value={item.id} name={item.id} onChange={handleCheckboxChange} size={'lg'}></Checkbox>
                                                    </Box>
                                                </HStack>
                                            </Box>
                                        ))}

                                    </VStack>

                                </Box>
                                <Box minHeight='50px'>
                                    {(eventDataObj.eventStatus.votingOpen && !eventDataObj.eventStatus.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='green' type={"submit"}>Vote Now!</Button>}
                                    {(eventDataObj.eventStatus.votingOpen && eventDataObj.eventStatus.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='red' type={"submit"} isDisabled={true}>Voting Closed</Button>}
                                    {(!eventDataObj.eventStatus.votingOpen && !eventDataObj.eventStatus.votingClosed) && <Button variant='solid' margin={'15px'} marginTop={'0px'} size={'lg'} colorScheme='yellow' type={"submit"}  isDisabled={true}>Voting Not Open</Button>}
                                    <br/><Button colorScheme="yellow" onClick={() => window.location.reload()}>Refresh Page</Button>
                                </Box>
                            </VStack>

                    </form>
                </Box>


            </Container>
        );
    }
    else if(justVoted && eventDataObj.checkResult){
        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'} borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Box>Thanks for Voting!</Box>
                        <Box>If the business you have submitted your details to is interested they will be in touch soon.</Box>
                        <Box><Button colorScheme="blue" onClick={event =>  logout()}> Go to Homepage </Button></Box>
                    </VStack>
                </Box>
            </Container>
        );
    }

}
