
import React from "react";
import {Box, Center, Container, Grid, GridItem, Heading, Text, Wrap, WrapItem} from "@chakra-ui/react";
import {Footer} from "../common/Footer";
import {useMediaQuery} from "react-responsive";

export default function Contact(){


    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    let myGridWidth, myContainerWidth, myContainerHeight;
    if(isTabletOrMobile){
        myContainerWidth='600px';
        myContainerHeight='auto';
    }
    else{
        myContainerWidth='1200px';
        myContainerHeight='autp';
    }

    return (

        <Container maxWidth={myContainerWidth} minHeight={myContainerHeight} overflow={"visible"} bg='transparent'>

            <Box maxWidth={myContainerWidth} bg={'white'} minHeight={myContainerHeight} height={"auto"} overflow={"auto"} marginTop={'50px'} padding={'20px'} borderRadius={'10px'}  className={'montserrat-free'} textAlign='justify'>


                        <Box paddingBottom={'15px'} display="flex"
                             alignItems="center"
                             justifyContent="center">
                            <a href={'/'}>
                            <img src="/Foundry%20Fuel_multi.jpg" width={'500px'} alt={'Foundry Fuel Logo'}/>
                            </a>
                        </Box>

                        <Box height={'auto'} bg='transparent' paddingBottom={'40px'}>
                            <Container maxWidth={myContainerWidth} bg='transparent' centerContent>
                                <Box maxWidth={myContainerWidth} padding={'20px'} bg='white' centerContent borderRadius={'10px'} className={'montserrat-free'} textAlign={"center"}>

                                <Heading as={'h2'} size={'xl'}>Contact Us</Heading>
                                    <Text marginBottom={'20px'}>You can contact us in a variety of ways.</Text>
                                    <Wrap>
                                        <WrapItem>
                                            <Box bg='white' w='300px' borderWidth='3px' borderRadius='lg' borderColor={'darkgray'} padding={'20px'} textAlign={'center'}>
                                                <Heading as={'h3'} size={'md'} marginY={'5px'}>Email</Heading>
                                                    It's not snail mail but it's a classic. Yep you can email us at <strong>contact@foundryfuel.com</strong>
                                            </Box>
                                        </WrapItem>
                                        <WrapItem>
                                            <Box bg='white' w='300px' borderWidth='3px' borderRadius='lg' borderColor={'cadetblue'} padding={'20px'} textAlign={'center'}>
                                                <Heading as={'h3'} size={'md'} marginY={'5px'} color={"cadetblue"}>Twitter / X</Heading>
                                                We're on Twitter/X and can be found under the handle <strong>@FuelTheFoundry</strong> or by clicking the following Image.
                                                <Center marginTop={'10px'}>
                                                <a href={'https://x.com/FuelTheFoundry'} target={"_blank"} title={'Follow us on X'}><img src='/x.png' width={'40px'}/></a>
                                                </Center>
                                            </Box>
                                        </WrapItem>
                                        <WrapItem>
                                            <Box bg='white' w='300px' borderWidth='3px' borderRadius='lg' borderColor={'royalblue'} padding={'20px'} textAlign={'center'}>
                                                <Heading as={'h3'} size={'md'} marginY={'5px'} color={"royalblue"}>LinkedIn</Heading>
                                                Its the social network for business and networking so of course we are on LinkedIn as well. You can search for us on there or by clicking the following Image.
                                                <Center marginTop={'10px'}>
                                                    <a href={'https://www.linkedin.com/company/foundry-fuel'}
                                                       target={"_blank"} title={'Follow us on LinkedIn'}><img
                                                        src='/linkedIn.png' width={'40px'}/></a>
                                                </Center>



                                            </Box>
                                        </WrapItem>
                                    </Wrap>
                                </Box>
                            </Container>
                        </Box>

            </Box>
            <Footer/>
        </Container>

    );

}