import {getAccessTokenFromCookie} from "../../../auth/TokenTools";
import axios from "axios";

export async function getEventsMobile(techID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "mobileEvents/" + techID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}

export async function getEventsMobileCode(shortcode) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "mobileEventCode/" + shortcode;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}