import axios from "axios";
import {getAccessTokenFromCookie} from "../../../auth/TokenTools";

export async function getFutureEvents(techID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "techEventInvitesUpcoming/" + techID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function getPastEvents(techID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "techEventInvitesPast/" + techID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function getEventAvailability(eventID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventAvailability/" + eventID;

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}

export async function acceptInvite(dataObject) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventattendinviteaccept/";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.put(URL, JSON.stringify(dataObject)));
}

export async function declineInvite(dataObject) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_EVENT_API + "eventattendinvitedecline/";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.put(URL, JSON.stringify(dataObject)));
}

export async function getVoteHistory(techID) {

    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "votingOppHistory/" + techID;
    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));
}


export async function getVotingStatus(techID, eventID) {



    var AccessToken = getAccessTokenFromCookie();
    const URL = process.env.REACT_APP_OPP_API + "votingOppHistory/" + techID;
    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    return (await client.get(URL));

}
