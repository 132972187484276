import {
    Box, Button, ButtonGroup,
    Container,
    Flex,
    Grid,
    GridItem, HStack, Link,
    ListItem,
    SimpleGrid,
    StackDivider,
    UnorderedList,
    VStack
} from "@chakra-ui/react";
import React from "react";
import {Banner} from "../common/Banner";
import {Menubar} from "../common/Menubar";
import {HomeTech} from "../cards/HomeTech";
import {HomeCurated} from "../cards/HomeCurated";
import {HomeAccountable} from "../cards/HomeAccountable";
import {HomeFree} from "../cards/HomeFree";
import {Footer} from "../common/Footer";
import {useMediaQuery} from "react-responsive";
import PreRegisterForm from "../forms/PreRegister";

export default function Home(){

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    let myGridWidth, myContainerWidth, myContainerHeight;
    if(isTabletOrMobile){
        myContainerWidth='600px';
        myContainerHeight='1500px';
        myGridWidth='repeat(1, 1fr)';
    }
    else{
        myContainerWidth='1200px';
        myContainerHeight='700px';
        myGridWidth='repeat(2, 1fr)';
    }

    return (

        <Container maxWidth={myContainerWidth} minHeight={myContainerHeight} overflow={"visible"} bg='transparent'>

            <Box maxWidth={myContainerWidth} bg={'white'} minHeight={myContainerHeight} height={"auto"}
                 overflow={"auto"} marginTop={'50px'} padding={'20px'} borderRadius={'10px'}
                 className={'montserrat-free'} textAlign='justify'>
                <Grid h='auto' templateRows='repeat(1, 1fr)' templateColumns={myGridWidth} gap={4}>
                    <GridItem colSpan={1} bg='white' padding={'10px'}>

                        <Box paddingBottom={'15px'}><img src="/Foundry%20Fuel_multi.jpg" width={'500px'}
                                                         alt={'Foundry Fuel Logo'}/></Box>


                        <h1 className={'montserrat-h1'}>Let's change how Tech teams are made</h1><br/>

                        It's frustrating being a Technology employee trying to find a
                        job you love. From bogus job posts looking for five years experience in a two year old
                        technology, to so called "hilarious" job adverts
                        with <strong><code>&lt;code&gt;</code></strong> in them. We know every engineer, tester,
                        project manager, scrum master, devops engineer (and so many more) are all unique, not just some
                        homogenous blob of tech drones. <strong><code>Foundry Fuel</code></strong> is a technology
                        platform for Technologists by
                        Technologists.<br/><br/>
                        <strong><code>Foundry Fuel</code></strong> makes promises (see our <a href={'/manifesto'}
                                                                                              className={'montserrat-link-little'}>Manifesto</a>)
                        and builds them into our platform.
                        We will never share data that
                        you don't want us to. We're
                        founder friendly and want to help more startups find the tech people they need through community
                        friendly curated events. We hold job
                        posters to account, ensuring key data is included in every post, and check in with you on
                        feedback to help us do
                        that. No more ghosting, no more false promises and no more bogus job ads.<br/><br/>
                        We want to change how Technologists find work, and we want you to be part of that
                        change.<br/><br/>
                        <h1 className={'montserrat-h1'}>Ready?</h1><br/><br/>
                        <ButtonGroup spacing='2'>
                            <Link href={process.env.REACT_APP_REG_LINK}>
                                <Button variant='solid' colorScheme='pink'>
                                    Register / Login
                                </Button>
                            </Link>
                        </ButtonGroup>

                    </GridItem>
                    <GridItem colSpan={1} bg='white'>
                        <Container maxWidth={'1200px'} bg='transparent' centerContent>
                            <SimpleGrid columns={1} spacing={2} marginX={"20px"} marginY={"20px"}>
                                <GridItem bg='none'><HomeFree/></GridItem>
                                <GridItem bg='none'><HomeTech/></GridItem>
                                <GridItem bg='none'><HomeCurated/></GridItem>
                                <GridItem bg='none'><HomeAccountable/></GridItem>
                            </SimpleGrid>
                        </Container>
                        <HStack marginTop={'10px'} spacing='14px' justify={'end'} marginRight={'40px'}>
                            <Box w='20px' h='20px' >
                                <a href={'https://x.com/FuelTheFoundry'} target={"_blank"} title={'Follow us on X'}><img src='/x.png' width={'20px'}/></a>
                            </Box>
                            <Box w='20px' h='20px'>
                                <a href={'https://www.linkedin.com/company/foundry-fuel'} target={"_blank"} title={'Follow us on LinkedIn'}><img src='/linkedIn.png' width={'20px'}/></a>
                            </Box>
                            <Box w='20px' h='20px'>
                                <a href={'https://youtube.com/@FoundryFuel'} target={"_blank"} title={'Learn more on YouTube'}><img src='/YT.png' width={'20px'}/></a>
                            </Box>
                        </HStack>
                    </GridItem>
                </Grid>
            </Box>
        <Footer/>
        </Container>

    );
}