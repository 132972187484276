import {
    Box,
    Button,
    Container,
    FormControl, Grid, GridItem, Heading,
    Input,
    InputGroup,
    InputLeftAddon, Text,
    useToast
} from "@chakra-ui/react";
import {postOpp} from "./scripts/FormData";
import {
    getCoreUserIDFromCog,
    getTechnologistIDFromCog,
    getTechProfile,
    postTechProfile
} from "../../../common/UserAPI/UserCalls";
import {getIDFromCookie} from "../../auth/TokenTools";
import {useEffect, useState} from "react";
import {useFormik} from 'formik';
import {getExperiences, getRegions, getSpecialisms} from "../../../forms/datasources/CommonObjects";
import {json} from "react-router-dom";
import {guardthispage} from "../../auth/PageTools";

export default function TechProfile() {

    // State Variables
    const [firstLoad, setFirstLoad] = useState(false);
    const [secondLoad, setSecondLoad] = useState(false);
    const [loadedForm, setLoadedForm] = useState(false);
    const [addressData, setAddressData] = useState("");
    const [date, setDate] = useState(new Date());
    const [coreID, setCoreID] = useState(0);
    const [techID, setTechID] = useState(0);
    const [submitted, setSubmitted] = useState(false);
    const [experience, setExperience] = useState(JSON.parse("{}"));
    const [specials, setSpecials] = useState(JSON.parse("{}"));
    const [regions, setRegions] = useState({});
    const [thisProfile, setThisProfile] = useState({});

    const toast = useToast()

    // Form filler functions
    if(!firstLoad) {
        guardthispage("Technologist");
        getIDFromCookie().then((response) => {
            getCoreUserIDFromCog(response).then((response2) => {
                getTechnologistIDFromCog(response).then((response3) => {
                    setFirstLoad(true);
                    setCoreID(response2);
                    setTechID(response3);

                    // Get the tech profile
                    const thisDataProfile = getTechProfile(response3);
                    thisDataProfile.then((value) => { setThisProfile(value); setSecondLoad(true);});

                    // Get the drop down data
                    const ExperienceData = getExperiences();
                    ExperienceData.then((value) => { setExperience(value.data);});
                    const SpecialismData = getSpecialisms();
                    SpecialismData.then((value) => { setSpecials(value.data);});
                    const RegionData = getRegions();
                    RegionData.then((value) => { setRegions(value.data);});

                });
            });

        });
    }

    const validate = values => {
        const errors = {};
        // ERROR Validation
        if (!values.name) { errors.name = 'Please enter your full name'; }
        if (!values.role) { errors.role = 'Please enter the name of your current role'; }
        if (values.experience==="") { errors.experience = 'Please select your experience level'; }
        if (values.region==="") { errors.region = 'Please select your region'; }
        if (values.specialismOne==="") { errors.specialismOne = 'Please select your first specialism'; }
        if (values.specialismTwo==="") { errors.specialismTwo = 'Please select your second specialism'; }
        if (values.specialismThree==="") { errors.specialismThree = 'Please select your third specialism'; }
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            role:'',
            website:'',
            linkedin: '',
            github: '',
            experience:'',
            region:'',
            specialismOne:'',
            specialismTwo: '',
            specialismThree:'',
        },
        validate,
        onSubmit: values => {
            const today = new Date().toISOString();
            let fullData = {
                TechID:techID,
                CoreID:coreID,
                Name:values.name,
                CurrentRole:values.role,
                Website:values.website,
                LinkedIn:values.linkedin,
                GitHub:values.github,
                Region:Math.floor(values.region),
                Experience:Math.floor(values.experience),
                SpecialismOne:Math.floor(values.specialismOne),
                SpecialismTwo:Math.floor(values.specialismTwo),
                SpecialismThree:Math.floor(values.specialismThree)
            }
            const dataObject = (JSON.stringify(values, null, 2));


            // Post the data object
            postTechProfile(JSON.stringify(fullData)).then(function (response) {
                //console.log(response);
                toast({
                    title: 'And we are off!',
                    description: "Your Profile has been updated",
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
            })
                .catch(function (error) {
                    toast({
                        title: 'Issues detected!',
                        description: "Nope sorry - something ain't working as expected",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                });

        },
    });

    if(firstLoad && secondLoad && thisProfile!==undefined && !loadedForm){
        //console.log(JSON.stringify(thisProfile));
        formik.values.name=thisProfile.name;
        formik.values.role=thisProfile.currentRole;
        formik.values.website=thisProfile.website;
        formik.values.linkedin=thisProfile.linkedIn;
        formik.values.github=thisProfile.gitHub;
        formik.values.experience=thisProfile.experience;
        formik.values.region=thisProfile.region;
        formik.values.specialismOne=thisProfile.specialismOne;
        formik.values.specialismTwo=thisProfile.specialismTwo;
        formik.values.specialismThree=thisProfile.specialismThree;
        setLoadedForm(true);
    }




    return (
        <Box padding={'10px'}  textColor={'darkslategray'}>
            <Grid templateRows='repeat(1, 1fr)' templateColumns='repeat(5, 1fr)' gap={10}>
                <GridItem colSpan={3} >
                    <Heading>Update Your Profile</Heading>
                    <Text marginY={'20px'}>Ensuring your profile is up to date is critical in matching your details as well as inviting you to the relevant events.</Text>
                    <FormControl textColor={'darkslategray'}>
                        <form onSubmit={formik.handleSubmit}>
                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Full Name</InputLeftAddon>
                                <Input id='name' name='name' type='name' onChange={formik.handleChange} value={formik.values.name}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Current Role</InputLeftAddon>
                                <Input id='role' name='role' type='role' onChange={formik.handleChange} value={formik.values.role}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Website</InputLeftAddon>
                                <Input id='website' name='website' type='website' onChange={formik.handleChange} value={formik.values.website}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>LinkedIn</InputLeftAddon>
                                <Input id='linkedin' name='linkedin' type='linkedin' onChange={formik.handleChange} value={formik.values.linkedin}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>GitHub</InputLeftAddon>
                                <Input id='github' name='github' type='github' onChange={formik.handleChange} value={formik.values.github}/>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Region</InputLeftAddon>
                                <Input as="select" name='region' onChange={formik.handleChange} value={formik.values.region}>
                                    <option key="" value="">Please Select</option>
                                    {regions.length > 0 && regions.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Experience</InputLeftAddon>
                                <Input as="select" name='experience' onChange={formik.handleChange} value={formik.values.experience}>
                                    <option key="" value="">Please Select</option>
                                    {experience.length > 0 && experience.map((option) => <option key={option.Id} value={option.Id}>{option.Display}</option>)}
                                </Input>
                            </InputGroup>

                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Specialism 1</InputLeftAddon>
                                <Input as="select" name='specialismOne' onChange={formik.handleChange} value={formik.values.specialismOne}>
                                    <option key="" value="">Please Select</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>
                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Specialism 2</InputLeftAddon>
                                <Input as="select" name='specialismTwo' onChange={formik.handleChange} value={formik.values.specialismTwo}>
                                    <option key="" value="">Please Select</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>
                            <InputGroup marginY={'5px'}>
                                <InputLeftAddon width={'120px'}>Specialism 3</InputLeftAddon>
                                <Input as="select" name='specialismThree' onChange={formik.handleChange} value={formik.values.specialismThree}>
                                    <option key="" value="">Please Select</option>
                                    {specials.length > 0 && specials.map((option) => <option key={option.Id} value={option.Id}>{option.Name}</option>)}
                                </Input>
                            </InputGroup>
                            <Button mt={4} colorScheme='teal' type='submit'>Submit</Button>
                        </form>
                    </FormControl>
                </GridItem>
                <GridItem colSpan={2} borderLeft={"solid"} paddingLeft={'20px'}>
                    <Heading>Validation</Heading>
                    <Text marginY={'20px'}>As you complete the form helpful text will appear here to help keep you on track.</Text>
                    {formik.errors.name ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.name}</Container> : null}
                    {formik.errors.role ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.role}</Container> : null}
                    {formik.errors.region ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.region}</Container> : null}
                    {formik.errors.experience ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.experience}</Container> : null}
                    {formik.errors.specialismOne ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.specialismOne}</Container> : null}
                    {formik.errors.specialismTwo ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.specialismTwo}</Container> : null}
                    {formik.errors.specialismThree ? <Container backgroundColor={'pink'} marginY={'7px'} borderRadius={'5px'} className={'montserrat-free'}>{formik.errors.specialismThree}</Container> : null}
                </GridItem>
            </Grid>
        </Box>
    );
}
