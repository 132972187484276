import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box, Button,
    Container, Grid, GridItem, Heading, Image, StackDivider,
    Text, VStack, Wrap, WrapItem
} from "@chakra-ui/react";
import {
    Stat,
    StatLabel,
    StatNumber,
    StatHelpText,
    StatArrow,
    StatGroup,
} from '@chakra-ui/react'
import React, {useState} from "react";
import address from "../../assets/host/address.jpg";
import bulbs from "../../assets/host/bulbs.jpg";
import desk from "../../assets/host/desk.jpg";
import frames from "../../assets/host/frames.jpg";
import manalive from "../../assets/host/manalive.jpg";
import redseats from "../../assets/host/redseats.jpg";
import screens from "../../assets/host/screens.jpg";
import woman from "../../assets/host/woman.jpg";
import {
    getHostDashboardStats,
    getHostIDFromCog,
    getHostApproved
} from "../../common/UserAPI/UserCalls";
import {getIDFromCookie} from "../auth/TokenTools";
import {getEvents, getPastEvents} from "./events/scripts/InviteData";
import HostDashStats from "./events/components/HostDashStats";
import {isMobile} from "react-device-detect";
import FFLogo from "../../assets/Foundry Fuel_multi.jpg";


export default function HostDash() {


    const [firstLoad, setFirstLoad] = useState(false);

    if(!firstLoad){
        getIDFromCookie().then((response) => {
            getHostIDFromCog(response).then((response2) => {
                getHostApproved(response2).then((response3) => {
                    if(!response3){
                        window.location.replace("/notyet");
                    }

                })
            });
        });
        setFirstLoad(true);
    }


    if(isMobile) {
        return (
            <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'}
                       borderRadius='lg' textAlign={"center"}>
                <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                    <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                        <Box h='140px' alignContent={'center'}>
                            <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                        </Box>
                        <Box>Mobile Restricted</Box>
                        <Box>Sorry but at present the dashboard isn't mobile compatible. Please access the dashboard using a desktop browser.</Box>

                    </VStack>
                </Box>
            </Container>
        )
    }
    else if(!isMobile) {
        return (
            <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'20px'} templateColumns='repeat(10, 1fr)'
                  gap={0} textColor={'slateGray'}>


                <GridItem rowSpan={1} colSpan={3} marginRight={'10px'} paddingRight={'10px'}>


                    <Heading marginBottom={'10px'}>Let's get started</Heading>
                    <Text marginBottom={'20px'}>As a <strong>Host</strong> on Foundry Fuel, your power lies in creating
                        events that Technologists and Founders/Businesses can attend.</Text>

                    <HostDashStats/>
                </GridItem>
                <GridItem rowSpan={1} colSpan={7} bg={'ghostwhite'} height={'auto'}>
                    <Accordion allowToggle width={'100%'} defaultIndex={[0]}>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>1 - Ensure you have an Address setup for your venue</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={address} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        Your address book is where you manage the location of all your event spaces. You
                                        will need to have this populated with at least one venue before you create an
                                        event.
                                        Create a venue in your address book now by clicking on the button
                                        below.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/createaddress'}>Create
                                            a Venue</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>2 - Create an event, set the parameters and restrictions</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={redseats} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        Creating an event is exactly as it sounds. You create a future event that you
                                        want to run at a selected venue from your address book. You give the event a
                                        name, theme, location and date and time.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/createEvent'}>Create
                                            an Event</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>3 - Curate the businesses pitching their opportunities at your
                                            event</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={bulbs} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        Curating an event is where you invite founders/businesses in your area to
                                        present their business opportunities to technologists. Do you have a theme, a
                                        topic or are you looking for certain levels of experience (founders for
                                        example)? This is your chance to select who you want pitching at your
                                        event.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/inviteOpps'}>Add
                                            Event Content</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>4 - Wait for replies on acceptance or rejection from those
                                            businesses</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={desk} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        Sit tight, grab a cuppa and keep an eye on your invites. You can keep track of
                                        your events through the Manage Event page. This allows you to see the details of
                                        your event and who has responded and how.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/manageEvent'}>Manage
                                            Event</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>5 - Lock in your event and send the invites to Technologists</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={woman} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        Happy with your event setup? Got confirmation from your invited
                                        businesses/founders? Ok it's time to lock in your event. When you lock in an
                                        event it can no longer be changed because we send invites out to the
                                        technologists in your area and messing with stuff is well, just not
                                        cool.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/manageEvent'}>Add
                                            Event Content</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>6 - Hold the event and get people to check in and vote</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={frames} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        When you create an event and that special time comes around we automatically
                                        provide you with two things. Firstly a check in page for your event where
                                        attendees need to check in so they can vote for the pitches they like. This can
                                        be printed out or put on a screen in your venue. Secondly we provide a mobile
                                        friendly voting page so that your users can vote. You need to open voting though
                                        which is just a click of a button. This can all be done on the Manage Event
                                        page.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/manageEvent'}>Manage
                                            Event</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton bg={'lightgray'} textColor={'slategray'}>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <strong>7 - Close the event and see the results</strong>
                                    </Box>
                                    <AccordionIcon/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Grid width={'100%'} templateRows='repeat(1, 1fr)' padding={'2px'}
                                      templateColumns='repeat(3, 1fr)' gap={0} textColor={'slateGray'}>
                                    <GridItem rowSpan={1} colSpan={1} bg={'ghostwhite'} height={'auto'}
                                              marginRight={'15px'} marginTop={'5px'}>
                                        <Image src={screens} alt='balloons image' borderRadius={'lg'} border={'solid'}
                                               marginBottom={'10px'}/>
                                    </GridItem>
                                    <GridItem rowSpan={1} colSpan={2} bg={'ghostwhite'} height={'auto'}>
                                        All done? When your event comes to an end and you want to wrap things up you can
                                        close the event which shuts down the ability to vote. Doing this encourages
                                        people to get those last votes in and generates the reports/connections/stats
                                        for the event.<br/><br/>
                                        Once again this can be done on the Manage Event page.<br/><br/>
                                        <Button colorScheme='blue'
                                                onClick={event => window.location.href = '/dashboard/host/manageEvent'}>Manage
                                            Event</Button>
                                    </GridItem>
                                </Grid>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </GridItem>
            </Grid>
        );
    }
}