import axios from "axios";
import {getAccessTokenFromCookie, getIDFromCookie} from '../../routes/auth/TokenTools'
import {jwtDecode} from "jwt-decode";
import Cookies from 'js-cookie';


const auth_api_url = process.env.REACT_APP_API_AUTH
const user_api_url = process.env.REACT_APP_API_USER

export function isLoggedIn () {

    // // checks the cog-code from the cookie to see if it exists (e.g. has logged in)
    // let cookies = document.cookie;
    // const cookieArray=cookies.split(";");

    const IDCookie = Cookies.get('ID');
    try{
        // var IDToken="";
        // cookieArray.forEach((element) => {if(element.indexOf("ID=")>=0){IDToken=element}});
        // IDToken = IDToken.replace("ID=","");

        const decoded = jwtDecode(IDCookie);
        const cogcode = decoded["sub"];
        return cogcode.length>0;
    }
    catch(e){
        return false;
    }

}

export async function getTechProfile(techID){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/tech/profile/" + techID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("tech doesnt exist");
    }

    return result;

}

export async function postTechProfile(dataObject){

    var AccessToken = getAccessTokenFromCookie();
    const URL = user_api_url + "/tech/profile";

    const client = axios.create({
        baseURL: URL,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(URL, dataObject));

}

export async function getCountries(){

    var result = [];
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/utilities/countries";

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("No Countries Found");
    }

    return result;

}

export async function getRegionsByCountry(countryID){

    var result = 0;
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/utilities/regions/" + countryID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("No Regions found");
    }

    return result;

}

export async function getUserTypeNameFromCog(userID){

    var result = "";
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/userTypeName/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - UserTypeName");
    }

    return result;

}

export async function getHostIDFromCog(userID){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/getHostID/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - Host");
        return result;
    }

    return result;

}

export async function getCoreUserIDFromCog(userID){

    var result = 0;
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/core/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - Core");
        return result;
    }

    return result;

}

export async function getBusinessIDFromCog(userID){

    var result = 0;
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/getBizID/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - Biz");
    }

    return result;

}

export async function getTechnologistIDFromCog(userID){

    var result = 0;
    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/users/getTechID/" + userID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - tech");
        return result;
    }

    return result;

}

export async function postLogo(id, image, type){

    const newLogo = {};
    newLogo.Id = id;
    newLogo.Image = image;

    var url = "";

    switch(type){
        case "biz":
            url= user_api_url + "/business/logo/";
            break;
        case "host":
            url= user_api_url + "/host/logo/";
            console.log("host logo update");
            break;
    }

    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:url,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(url, newLogo));

}

export async function getLogo(id){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/biz/logo/" + id;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Logo not found");
    }

    return result;

}

export async function getHostLogo(id){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/logo/" + id;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Logo not found");
    }

    return result;

}

export async function postAddress(Address, AddressBookID){

    const newAddress = {};
    newAddress.Id = Address.Id;
    newAddress.Name = Address.Name;
    newAddress.Number = Address.Number;
    newAddress.Street = Address.Street;
    newAddress.Town = Address.Town;
    newAddress.City = Address.City;
    newAddress.Postcode = Address.Postcode;
    newAddress.CountryID = Address.CountryID;
    newAddress.RegionID = Address.RegionID;
    newAddress.AddressBookId = AddressBookID;

    var url= user_api_url + "/host/address";

    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:url,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(url, newAddress));

}

export async function updateAddress(Address){

    const newAddress = {};
    newAddress.ID = Address.ID;
    newAddress.Name = Address.Name;
    newAddress.Number = Address.Number;
    newAddress.Street = Address.Street;
    newAddress.Town = Address.Town;
    newAddress.City = Address.City;
    newAddress.Postcode = Address.Postcode;
    newAddress.CountryID = Address.CountryID;
    newAddress.RegionID = Address.RegionID;
    newAddress.AddressBookId = Address.AddressBookID;

    var url= user_api_url + "/host/addressupdate";

    var AccessToken = getAccessTokenFromCookie();

    const client = axios.create({
        baseURL:url,
        headers: {'content-type': 'application/json', Authorization : `Bearer ${AccessToken}`}
    })

    return (await client.post(url, newAddress));

}

export async function getAddressBookFromHostID(hostID){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/abid/" + hostID;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("User doesnt exist - Address Book");
    }

    return result;

}

export async function getAddressFromID(addressId){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/address/" + addressId;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Address doesnt exist");
    }

    return result;

}

export async function deleteAddressFromID(addressId){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/deleteaddress/" + addressId;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Address doesnt exist");
    }

    return result;

}

export async function getHostDashboardStats(hostId){

    var result = 0;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/dashstats/" + hostId;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("host doesnt exist");
    }

    return result;

}

export async function getHostApproved(hostId){

    var result = false;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/host/approved/" + hostId;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Host not found");
    }

    return result;

}

export async function getBusinessApproved(businessId){

    var result = false;

    const AccessToken = getAccessTokenFromCookie();
    const myUrl= user_api_url + "/business/approved/" + businessId;

    const client = axios.create({
        baseURL: myUrl,
        headers: {'content-type': 'application/x-www-form-urlencoded', Authorization : `Bearer ${AccessToken}`}
    })
    try{

        await client.get(myUrl).then(response => {
            result = response.data;
        })
    }
    catch (ex){
        console.log("Business not found");
    }

    return result;

}










