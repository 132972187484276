import React, {useEffect, useState} from "react";
import {useCookies} from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {getRegStatus} from "../../common/DataAPI/AuthCalls";
import {getIDFromCookie} from "./TokenTools";
import Cookies from 'js-cookie';

export default function NewTokenGrabber(props){


    const [posts, setPosts] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const navigate = useNavigate();
    const [cogID, setCogID] = useState("0")

    const URL= process.env.REACT_APP_API_AUTH + "/CogCode?code=";

    const client = axios.create({
        baseURL:URL + props.code
    })

    useEffect(() => {

            client.post(URL + props.code,{}).then((response) => {

                try{
                    //console.log(response.data);
                    let JSONtokens = JSON.parse(response.data);
                    setCookie('Access', JSONtokens.access_token, { path: '/' });
                    setCookie('Refresh', JSONtokens.refresh_token, { path: '/' });
                    setCookie('ID', JSONtokens.id_token, { path: '/' });
                    setPosts(response.data);

                    getIDFromCookie().then((response) => {
                        setCogID(response);
                        try {
                            getRegStatus(response).then((response2) => {
                                if(response2.length>0) {
                                    var obj = JSON.parse(response2);
                                    if (obj.complete) {
                                        try {
                                            const token = Cookies.get('eventID');
                                            if(token.length>0){
                                                navigate('/dashboard/tech/vote?event=' + token);
                                            }
                                            else {
                                                navigate('/dashboard');
                                            }
                                        }
                                        catch (e) {
                                            navigate('/dashboard');
                                        }
                                    }
                                    else{

                                        try {
                                            const token = Cookies.get('eventID');
                                            if(token.length>0){
                                                navigate('/dashboard/tech/vote?event=' + token);
                                            }
                                            else {
                                                navigate('/register/choose');
                                            }
                                        }
                                        catch (e) {
                                            navigate('/register/choose');
                                        }
                                    }
                                }
                                else{
                                    try {
                                        const token = Cookies.get('eventID');
                                        if(token.length>0){
                                            navigate('/dashboard/tech/vote?event=' + token);
                                        }
                                        else {
                                            navigate('/register/choose');
                                        }
                                    }
                                    catch (e) {
                                        navigate('/register/choose');
                                    }
                                }
                            })
                        }
                        catch (ex){

                            console.log(ex);
                        }

                    });

                }
                catch(err){
                    console.log("ERR 101 - Cannot parse token");
                }

            });



            }, []
    )
};
