import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Box,
    Button, Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    VStack,
} from "@chakra-ui/react";
import {useNavigate} from "react-router-dom";
import {getEventsMobileCode} from "../scripts/EventsData";
const siteKey = process.env.REACT_APP_DOMAIN;

// Validation schema using Yup
const validationSchema = Yup.object({
    name: Yup.string()
        .min(3, "Code must be at least 5 characters")
        .required("A code is required"),
});

const AttendNowForm = () => {

    const navigate = useNavigate();

    return (
        <Box maxW="md" mx="auto" borderWidth="1px" borderRadius="lg" p={2} m={3} boxShadow="sm" _hover={{ boxShadow: "md", transform: "scale(1.02)" }}>
            <Formik
                initialValues={{ name: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, actions) => {

                    getEventsMobileCode(values.name).then(result => {
                        if (result.data) {
                            window.open(siteKey + "/dashboard/tech/vote?event=" + result.data.code,"_self")
                        }
                        else{
                            alert('Event not found!');
                        }
                    })

                    //alert(JSON.stringify(values.name, null, 2));
                    actions.setSubmitting(false);
                }}
            >
                {({ errors, touched, isSubmitting }) => (
                    <Form>
                        <VStack spacing={4}>
                            <Container fontSize={13}>If you are at an event and the host provides you with a code, enter it here.</Container>
                            <Field name="name">
                                {({ field }) => (
                                    <FormControl isInvalid={errors.name && touched.name}>
                                        <Input textAlign="center" display="flex" justifyContent="center" {...field} id="name" placeholder="Enter the event code" />
                                        <FormErrorMessage textAlign="center" display="flex" justifyContent="center">{errors.name}</FormErrorMessage>
                                    </FormControl>
                                )}
                            </Field>

                            <Button type="submit" colorScheme="blue" isLoading={isSubmitting} size={'md'}> Submit </Button>
                        </VStack>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default AttendNowForm;