import {Box, Button, Center, Container, HStack, StackDivider, VStack} from "@chakra-ui/react";
import FFLogo from "../../../assets/Foundry Fuel_multi.jpg";
import {FaRegUserCircle} from "react-icons/fa";
import React from "react";


export default function MobileLogin() {

    const linkAddress=process.env.REACT_APP_COGNITO_REG_GEN;

    return (
        <Container width={'350px'} marginY={'30px'} padding={'15px'} bg='white' borderColor={'lightgray'}
                   borderRadius='lg' textAlign={"center"}>

            <Box width={'320px'} margin={'0px'} padding={'0px'} marginTop={'20px'} borderRadius='lg'>
                <VStack divider={<StackDivider borderColor='gray.200'/>} spacing={4} align='stretch'>
                    <Box h='140px' alignContent={'center'}>
                        <center><img src={FFLogo} alt="Foundry Fuel Logo" width={'250px'}/></center>
                    </Box>
                    <Box> Let's get you logged in </Box>
                    <Box backgroundColor={'white'}>
                        <Center bg="white" h="30px" color="white">
                            <a href={linkAddress}>
                                <Box borderRadius={'10'} height={'30px'} width={'170px'} paddingTop={'4px'}
                                     paddingLeft={'7px'} bgColor={'white'}>
                                    <HStack padding={'0'} spacing={'2'} color={'#183C67'}>
                                        <FaRegUserCircle color={'#183C67'} size={'20'}/><p
                                        className={'montserrat-header-right'}>Register / Login</p>
                                    </HStack>
                                </Box>
                            </a>
                        </Center>
                    </Box>
                </VStack>
            </Box>


        </Container>
    );
}